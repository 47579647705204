import React from 'react'
import styled, { keyframes, css } from 'styled-components'
import Link from '../components/Link'
import colors from '../styles/colors'
import typography from '../styles/typography'

const fadeIn = keyframes`
    from{ opacity: 0; }
    to{ opacity: 1; }
`

const StyledButton = styled.button`
   
    ${({ $size }) => {
        switch ($size) {
            case 'small': return css`
                height: 40px;
                width: 75px;
                font-size: 0.9rem;
            `
            case 'medium': return css`
                height: 40px;
                width: 150px;
                font-size: 1rem;
            `
            case 'large': return css`
                height: 55px;
                width: 190px;
                font-size: 1.3rem;
            `
            default: return css`
                height: 50px;
                width: 200px;
                font-size: 1.2rem;
            `
        }
    }};

    ${({ $center }) => (
        $center ? css`
            position:absolute;
            top:50%;
            left:50%;
            margin: auto;
            transform: translate(-50%,-50%);
        `
            : css`
            position:relative;
        `
    )};

    background: ${props => props.$disabled ? `${colors.secondary}aa` : `${colors.black}aa`};
    cursor: ${props => props.$disabled ? 'not-allowed' : 'pointer'};
    border:0;
    opacity:1;
    border-radius: 1.7rem;
    margin: auto;
    transition: 0.3s ease-out;
    text-decoration:none;
    font-family: ${typography.fonts.primaryAllCaps};
    text-align:center;
    color: white;
    letter-spacing: 0.1rem;
    display: flex;
    align-items:center;
    justify-content: center;
    user-select:none;
    animation: ${props=>props.$fadeIn ? `${fadeIn} 1.2s ease 1.2s 1 normal backwards`:`none`};

    &:hover {
        background: ${props => props.$disabled ? `${colors.secondary}aa` : `${colors.black}cc`};
        transition: 0.3s ease-out;
    }
`

const Button = ({ children, size, fadeIn, linkTo, disabled, center, ...rest }) => {
    return (
        <StyledButton $disabled={disabled} $center={center} $size={size} as={linkTo && Link} to={linkTo} $fadeIn={fadeIn} {...rest}>
            {children}
        </StyledButton>
    )
}

export default Button
