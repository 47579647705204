import React from "react"
import Head from "../components/Head"
import styled, { keyframes } from "styled-components"
import Button from "../components/Button"
import breakpoints from "../styles/breakpoints"
import Img from "../components/Img"
import brushStroke from "../../assets/brushStroke.png"
import useBreakpoint from "../hooks/useBreakpoint"
import ClientOnly from "../components/ClientOnly"

const BrushStrokeLtR = keyframes`
    from{ clip-path: inset(0px 100% 0px 0);}
    to{ clip-path: inset(0px 0px 0px 0); }
`

const BrushStroke = styled(Img)`
    align-self: center;
    z-index: -998;
    background-image: url(${brushStroke});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    /* clip-path: inset(0px 100% 0px 70%); */
    animation: ${BrushStrokeLtR} 1s ease-in 0s 1 normal forwards;

    @media (${breakpoints.tabletLandscapeUp}) {
        max-width: 85%;
        min-height: 200px;
        margin-left: 30px;
    }

    min-height: 115px;
`

const IndexPage = () => {
    const breakpoint = useBreakpoint()

    return (
        <>
            <Head title="Landing"></Head>
            <BrushStroke src={brushStroke}></BrushStroke>
            <ClientOnly>
                <Button
                    size={breakpoint.tabletUp ? "large" : "medium"}
                    center={true}
                    linkTo="/artwork/"
                >
                    Explore The Art
                </Button>
            </ClientOnly>
            </>
    )
}

export default IndexPage
